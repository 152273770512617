@import '../../../shared/styles/variable.scss';

.notifyListItem {
  padding: 8px 20px;
  // border-bottom: 0 none !important;
  border-bottom: 1px solid #f2eeee !important;
}

.NotificationLu{

  // right: -9px;
  // top: 5px;
  background-color: #1e568d;
  padding: 4px 23px;
  color: #fff;
  border-radius: 25px;
  font-weight: 600;
  width: 66px;
  height: 27px;
}

.NotificationNonLu{

  // right: -9px;
  // top: 5px;
  background-color: #df0000;
  padding: 4px 9px;
  color: #fff;
  border-radius: 25px;
  font-weight: 600;
  width: 66px;
  height: 27px;
}



.notifyMessageAvatar {
  margin-right: 16px;
  width: 48px;
  height: 48px;

  [dir=rtl] & {
    margin-right: 0;
    margin-left: 16px;
  }
}

.notificationNoVue{
  background-color: #eaeaea;
}

.notifyLinkItem{
  cursor: pointer;
  text-decoration: none;
}

.notifyMessageContent {
  position: relative;
  font-size: $font-size-base;
  color: $text-color-secondary;
  margin-left: 17px;
  & h3 {
    font-size: $font-size-base;
    color: $text-color;
    font-weight: $font-weight-medium;
    margin-bottom: 2px;
  }

  & p {
    margin-bottom: 0;
  }
}