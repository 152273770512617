@import '../../../../../shared/styles/variable.scss';

.appLogo {
  cursor: pointer;
  min-height: 148px;
  & img {
    max-height: 95px;
    padding-left: 15px;
    margin-top: 15px;
    max-width: 60%;
  }
}

