@import '../../../shared/styles/variable.scss';

.appSearchBar {
  width: 410px;
  border-right: 1px solid #e8e8e8;

  & :global(.form-control) {
    padding-left: 43px;
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 14px;
    font-style: italic;
    transition: all .2s ease;

    @include media-breakpoint-down(sm) {
      width: 120px;
    }

    &:focus {

      @include media-breakpoint-down(sm) {
        width: 145px;
      }
    }

    [dir='rtl'] & {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}

.searchBtn {
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 31px;
  padding: 0;
  width: 84px;
  justify-content: center;

  [dir='rtl'] & {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
