@import "variable";

:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;
  font-family: 'Montserrat', sans-serif;

}

body {
  line-height: 1.35 !important;
  font-family: 'Montserrat', sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  line-height: 1.35;
}

img {
  max-width: 100%;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: none;
}

.form-control:focus, .btn-primary:focus{
  box-shadow: none!important;
}

.offcanvas-start {

  [dir='rtl'] & {
    left: auto;
    transform: translateX(100%);

    &.show {
      transform: none;
    }
  }
}

.offcanvas-end {

  [dir='rtl'] & {
    right: auto;
    transform: translateX(-100%);

    &.show {
      transform: none;
    }
  }
}

.notificationHeaderContainer{
  
  .iconNotificationBtn{
    color: #000;
    font-size: 24px;
    min-width: 45px;
    min-height: 45px;
  }
}

.dropdown-menu{
  border-radius: 0;
  margin-top: 4px;
  padding: 14px 0;
  box-shadow: 0px 0px 29px 3px rgb(0 0 0 / 13%);
  border: none;
  min-width: 216px;
}
.dropdown-item {
  padding: 10px 29px;
  min-height: 40px;
  font-size: 14px;
}

.logout-dropdown {
  color: #113c65;
  font-weight: 600;
}

.dropdown-item > svg {
  margin-right: 15px;
}

.dropdown-divider {
  border-top-color: #f5f5f5;
  opacity: 1;
}

h1, .h1 {
  font-size: 24px;
  font-weight: 600;
}

.custom-card {
  background: #fff;
  padding: 15px;
  margin-bottom: 30px;
}

.custom-title-card{
  font-size: 18px;
  font-weight: 600;
  color: #113c65;
  position: relative;
}

@media (min-width: 992px){
  .custom-card{
    padding: 32px 38px 55px;
  }
}

.custom-title-card span {
  background: #fff;
  z-index: 2;
  position: relative;
  padding-right: 15px;
}

.custom-title-card:before {
  content: '';
  display: block;
  width: 100%;
  border-top: 1px solid #f1f1f1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}

.form-check{

  .form-check-input{
    margin-top: 0;
  }

  label {
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
  }
}

.mb-10{
  margin-bottom: 10px;
}

.btn {
  min-width: 135px;
}
.mr-btn{
  margin-right: 12px;
}
.eyebtn{
  margin-right: 17px;
}
.header-select-orderby{
  min-width: 241px;
  width: 100%;
  color: #8b8b8b;
}
.header-btn{
  min-width: 279px;
  width: 100%;
  padding: 14px 10px;
  margin-left: 15px;

  svg{
    margin-right: 10px;
  }
}
.header-label{
  min-width: 100px;
  font-weight: 500;
}