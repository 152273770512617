@import '../../../../../../shared/styles/variable.scss';

.verticalNavGroup {
  height: 40px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-right: 12px;
  font-weight: $font-weight-semi-bold;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
}