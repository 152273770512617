@import '../../../../../shared/styles/variable.scss';

.menuColorCell {
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.menuColorCellHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.menuColorCellHeaderAvatar {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: $border-radius-circle;
}

.menuColorCellHeaderContent {
  width: 100%;
  padding-left: 16px;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: 16px;
  }
}

.menuColorCellHeaderContentLine {
  width: 50px;
  height: 4px;

  & + & {
    margin-top: 8px;
  }
}

.menuColorCellContent {
  max-width: 200px;
  position: relative;
}

.menuColorCellMenuItem {
  width: 100%;
  min-height: 40px;
  padding: 10px 18px;
  white-space: nowrap;
  font-size: $font-size-sm;

  @include media-breakpoint-up(xxl) {
    font-size: $font-size-base;
  }

  &.menuColorCellMenuItemSelected {
    padding: 10px;
    border-radius: $border-radius;
    width: calc(100% - 16px);
    position: relative;
    transition: all 0.4s ease;
    margin-left: 8px;
    margin-right: 8px;
    font-size: $font-size-sm;

    @include media-breakpoint-up(xxl) {
      font-size: $font-size-base;
    }

    &.standardMenu {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      padding-left: 30px;
      padding-right: 12px;
      border-radius: 0;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background-color: $primary;
      }
    }

    &.roundedMenu {
      margin-left: 0;
      margin-right: 8px;
      width: calc(100% - 8px);
      padding-left: 18px;
      padding-right: 10px;
      border-radius: 0 30px 30px 0;

      [dir=rtl] & {
        margin-right: 0;
        margin-left: 8px;
        padding-left: 10px;
        padding-right: 18px;
        border-radius: 30px 0 0 30px;
      }
    }

    &.roundedMenuReverse {
      margin-left: 8px;
      margin-right: 0;
      width: calc(100% - 8px);
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 30px 0 0 30px;
    }

    &.active:after {
      display: none;
    }
  }
}