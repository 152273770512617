@import '../../../../../../shared/styles/variable.scss';

.menuVerticalCollapse {
  border-left: 8px solid transparent;
  height: 73px;
  margin-left: 1px;
  margin-right: 1px;
  padding-left: 59px;
  padding-right: 12px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  transition: all 0.4s ease;
  cursor: pointer;

  &.open {
    border-left-color: #194d7f;
    background-color: #0d3458;
  }

  & .navItemText {
    font-weight: 300;
  }

  & .navItemIcon {
    font-size: 16px;
    display: block;
    margin-right: 16px;

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  & .navItemIconArrow {
    font-size: 20px;
  }
}

.subMenuCollapse {
  position: relative;
  box-shadow: inset 0 -29px 29px -29px rgb(0 0 0 / 13%);
  background-color: #113c65;
  display: flex;
  align-items: center;
  border-left: 8px solid #194d7f;

  &.open {
    color: inherit;
    min-height: 137px;
  }
}

.navItemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}