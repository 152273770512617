//@import "variable-dark";
$border-radius:                0px;
$border-color:               #444444;
$gray-400:                   #f4f4f4;
$body-color:                 #000;
$input-font-weight:            500;
$font-size-base:               0.875rem;
$input-btn-padding-y:          0.875rem !default;
$input-btn-padding-x:          1.4375rem !default;
$grid-gutter-width:            0.625rem !default;
$grid-gutter-width:            0.625rem;
$form-check-input-border-radius: 0em;
$form-check-input-border:      1px solid #444444;
$form-check-input-width:       18px;
$form-check-input-height:      19px;
$form-check-padding-start:     0px;
$input-placeholder-color:    #444444;
$primary:                    #113c65;
$btn-line-height:               1.4;
$blue: #113c65;



@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Override default variables before the import

// scss-docs-start theme-color-variables
$primary: #113c65;
$secondary:     #F04F47;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;
$white:         #fff;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark,
        "white":      $white
);
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// scss-docs-end theme-colors-

/*LIGHT THEME VARIABLE*/

//Custom Color system
$text-color: #000 !default;
$text-color-secondary: rgb(107, 114, 128) !default;
$text-color-disabled: rgb(149, 156, 169) !default;
$text-color-hint: #aeafb8 !default;
$enable-negative-margins: true;
// Body Settings for the `<body>` element.

$body-bg:                   #f5f5f5;
$body-color:                $text-color;
$component-bg:              $white;
$border-color:                rgba(0, 0, 0, 0.12);
/*ENDED THE LIGHT THEME VARIABLE*/


/*DARK THEME OLY for dar theme*/
/*//Custom Color system
$text-color: rgb(255,255,255) !default;
$text-color-secondary: rgb(229, 231, 235) !default;
$text-color-disabled: rgb(156, 163, 175) !default;
$text-color-hint: #c6c7cc !default;
$border-color:                rgba(255, 255, 255, 0.2);

// Body Settings for the `<body>` element.
$body-bg:                   #1F2527;
$body-color:                #fff;
$component-bg:              #2B3137;*/

/*ENDED THE DARK THEME VARIABLE*/

// Links
//
// Style anchor elements.

$link-color:                              $primary;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage);

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;

// scss-docs-start box-shadow-variables
$box-shadow:                  0 0 5px 5px rgba(0,0,0,0.03);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Montserrat', sans-serif;
// stylelint-enable value-keyword-case
$font-family-base:            var(--#{$variable-prefix}font-sans-serif);

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-base:              14px; // Assumes the browser default, typically `14px`
$font-size-sm:                12px;
$font-size-lg:                16px;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semi-bold:       600;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;

$line-height-base:            1.35;
$line-height-sm:              1.25;
$line-height-lg:              2;

$h1-font-size:                22px;
$h2-font-size:                20px;
$h3-font-size:                18px;
$h4-font-size:                $font-size-lg;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size
) !default;
// scss-docs-end font-sizes

// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               0px;
$border-radius-sm:            2px;
$border-radius-lg:            8px;
$border-radius-xl:            16px !default;
$border-radius-30:            30px !default;
$border-radius-pill:          50rem;
$border-radius-circle:        50% !default;
$card-radius:                 16px !default;
$card-bg:                     $component-bg;
// scss-docs-end border-radius-variables

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           1.875rem;

// sidebar variable
$sidebar-bg-color: $white;
$sidebar-text-color: $text-color;
$sidebar-dark-bg-color: #001529;
$sidebar-curved-menu-color: #F4F7FE;