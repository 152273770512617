@import '../../../../shared/styles/variable';

.appBitBucketLayout {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  &.appMainFixedFooter {
    padding-bottom: 46px;

    & .appMainFooter {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
    }
  }

  &.boxedLayout {
    @include media-breakpoint-up(xxl) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1360px;
      border-left: solid $border-width $border-color;
      border-right: solid $border-width $border-color;
    }
  }

  &.framedLayout {
    @include media-breakpoint-up(xxl) {
      padding: 20px;
      background-color: $primary;

      & .appBitBucketLayoutInner {
        border-radius: 12px;
        background-color: $body-bg;
      }

      & .bucketMinibar {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        [dir='rtl'] & {
          border-radius: 0 12px 12px 0;
        }
      }

      & .appMainFooter {
        border-bottom-right-radius: 12px;

        [dir='rtl'] & {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 12px;
        }
      }

      & .bucketMinibarScrollbar,
      & .appBitBucketSidebarScrollbar {
        height: calc(100vh - 110px) !important;
      }

      & .bitBucketMainScrollbar {
        height: calc(100vh - 40px);
      }

      & :global(.apps-container) {
        height: calc(100vh - 142px);
      }
    }
  }
}

.appBitBucketLayoutInner {
  position: relative;
  background-color: transparent;
  display: flex;
}

.appBitBucketSidebarWrapper {
  display: none;
  width: 320px !important;
  transition: all 0.1s linear;
  position: relative;

  border-right: solid $border-width $border-color;
  @include media-breakpoint-up(xl) {
    display: flex;
  }
}

.appBitBucketSidebarWrapperClose {
  width: 72px !important;

  & .appBitBucketSidebar {
    min-width: 0 !important;
    width: 8px !important;
    transition: all 0.1s linear;
    overflow: hidden;
  }

  & + .appBitBucketLayoutMain {

    @include media-breakpoint-up(xl) {
      width: calc(100% - 72px) !important;
    }
  }
}

.bitbucketBtn {
  position: absolute;
  top: 20px;
  right: -12px;
  z-index: 3;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: $white;
  padding: 2px;
  font-size: 24px;
  border-radius: $border-radius-circle;
  cursor: pointer;

  [dir=rtl] & {
    right: auto;
    left: -12px;
  }

  &:hover,
  &:focus {
    background-color: $primary;
    color: $white;
  }
}

.bucketMinibar {
  display: flex;
  flex-direction: column;
  width: 64px;
  min-width: 64px;
  background-color: $sidebar-dark-bg-color;
  color: inherit;
  border-right: $border-width solid rgba($sidebar-dark-bg-color, 0.15);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.05);
  }

  &.dark {
    &:before {
      background-color: rgba($white, 0.5);
    }
  }

  & .bucketMinibarInner {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
  }

  [dir=rtl] & {
    border-right: 0 none;
    border-left: $border-width solid rgba($sidebar-dark-bg-color, 0.15);
  }

  & a {
    color: inherit;
    cursor: pointer;
  }
}

.bucketMinibarLogo {
  min-height: 56px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    min-height: 70px;
  }
}

.bucketMinibarScrollbar {
  display: flex;
  flex-direction: column;
  padding: 12px;
  height: calc(100vh - 56px) !important;

  @include media-breakpoint-up(md) {
    height: calc(100vh - 70px) !important;
  }

  & :global(.simplebar-content) {
    height: 100%;
  }
}

.bucketMinibarMain {
  text-align: center;
  display: flex;
  flex-direction: column;

  & .bucketMinibarLink {
    margin-bottom: 10px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: inherit;
    padding: 9px;
    border-radius: $border-radius-circle;
    line-height: 1;
  }

  & .bucketMinibarNotifyLink {
    font-size: 20px;
  }
}

.bucketMinibarLang {
  margin-bottom: 10px;
}

.bucketMinibarSetting {
  text-align: center;
  margin-top: auto;
}

.appBitBucketSidebar {
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 256px !important;
  width: auto !important;
  transition: all 0.1s linear;

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    width: 190px !important;
  }
}

.appBitBucketHeader {
  padding-left: 20px;
  padding-right: 20px;
  color: $text-color;
  background-color: $component-bg;
  height: 56px;
  line-height: 1;
  box-shadow: 4px 3px 4px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.1s linear;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    padding-left: 30px;
    padding-right: 30px;
    height: 70px;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }

  & .trigger {
    font-size: 24px;
    margin-right: 20px;
    padding: 5.5px;
    color: $text-color;
    cursor: pointer;

    & svg {
      display: block;
    }

    [dir=rtl] & {
      margin-right: 0;
      margin-left: 20px;
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

.appBitBucketLayoutMain {
  transition: all 0.1s linear;
  width: 100% !important;
  position: relative;

  @include media-breakpoint-up(xl) {
    width: calc(100% - 320px) !important;
  }

  & :global(.apps-container) {

    @include media-breakpoint-up(xl) {
      height:  calc(100vh - 102px);
    }
  }
}

.bitBucketMainScrollbar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 56px);

  .appMainFixedFooter & {
    max-height: calc(100vh - 102px);
  }

  @include media-breakpoint-up(md) {
    max-height: calc(100vh - 70px);

    .appMainFixedFooter & {
      max-height: calc(100vh - 116px);
    }
  }

  @include media-breakpoint-up(xl) {
    max-height: 100vh;

    .appMainFixedFooter & {
      max-height: calc(100vh - 48px);
    }
  }
}

.appBitBucketSidebarScrollbar {
  height: calc(100vh - 56px) !important;

  .appMainFixedFooter & {
    height: calc(100vh - 102px) !important;
  }

  @include media-breakpoint-up(md) {
    height: calc(100vh - 70px) !important;

    .appMainFixedFooter & {
      height: calc(100vh - 116px) !important;
    }
  }
}

// Sidebar Background Image
.bitBucketSidebarImgBackground {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    z-index: 1;
    opacity: 0.5;
  }

  & > div {
    position: relative;
    z-index: 3;
  }
}

.appBitBucketSidebarInner {
  display: flex;
  flex-direction: column;
}

//BitBucket Drawer
.appBitBucketDrawer {
  width: 20rem !important;

  & .appBitBucketSidebarWrapper {
    display: flex;
    width: auto !important;
  }

  & .crUserInfo {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

